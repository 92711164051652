.failedmetric {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.title-wrapper {
  align-self: stretch;
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.spandiv {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 0px 3px 0px 0px;
  position: relative;
}

.element-of {
  color: var(--neutral-color-grey-950);
  font-family: "SF Pro Display-Medium", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.failed-count-wrapper {
  color: #ef4d4f;
  font-family: var(--heading-m-h3-font-family);
  font-size: var(--heading-m-h3-font-size);
  font-style: var(--heading-m-h3-font-style);
  font-weight: var(--heading-m-h3-font-weight);
  letter-spacing: var(--heading-m-h3-letter-spacing);
  line-height: var(--heading-m-h3-line-height);
}

.total-count-wrapper {
  color: #c5c5c6;
  font-family: var(--paragraph-SB-p1-font-family);
  font-size: var(--paragraph-SB-p1-font-size);
  font-style: var(--paragraph-SB-p1-font-style);
  font-weight: var(--paragraph-SB-p1-font-weight);
  letter-spacing: var(--paragraph-SB-p1-letter-spacing);
  line-height: var(--paragraph-SB-p1-line-height);
}
