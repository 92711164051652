@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --collection-BG-100: rgba(44, 44, 44, 1);
  --collection-BG-200: rgba(32, 32, 32, 1);
  --heading-m-h4-font-family: "SF Pro Display", Helvetica;
  --heading-m-h4-font-size: 20px;
  --heading-m-h4-font-style: normal;
  --heading-m-h4-font-weight: 500;
  --heading-m-h4-letter-spacing: 0px;
  --heading-m-h4-line-height: 24px;
  --info-color-IC-base: rgba(58, 112, 226, 1);
  --neutral-color-grey-1000: rgba(250, 250, 250, 1);
  --neutral-color-grey-150: rgba(58, 60, 62, 1);
  --neutral-color-grey-750: rgba(197, 197, 198, 1);
  --neutral-color-grey-900: rgba(232, 232, 232, 1);
  --paragraph-m-p2-font-family: "SF Pro Text", Helvetica;
  --paragraph-m-p2-font-size: 14px;
  --paragraph-m-p2-font-style: normal;
  --paragraph-m-p2-font-weight: 500;
  --paragraph-m-p2-letter-spacing: 0px;
  --paragraph-m-p2-line-height: 20px;
  --collection-BG-100: rgba(44, 44, 44, 1);
  --collection-BG-200: rgba(32, 32, 32, 1);
  --info-color-IC-base: rgba(58, 112, 226, 1);
  --neutral-color-grey-1000: rgba(250, 250, 250, 1);
  --neutral-color-grey-150: rgba(58, 60, 62, 1);
  --neutral-color-grey-750: rgba(197, 197, 198, 1);
  --neutral-color-grey-900: rgba(232, 232, 232, 1);
  --paragraph-m-p2-font-family: "SF Pro Text", Helvetica;
  --paragraph-m-p2-font-size: 14px;
  --paragraph-m-p2-font-style: normal;
  --paragraph-m-p2-font-weight: 500;
  --paragraph-m-p2-letter-spacing: 0px;
  --paragraph-m-p2-line-height: 20px;
  --caption-m-SB-c-m-font-family: "SF Pro Text", Helvetica;
  --caption-m-SB-c-m-font-size: 11px;
  --caption-m-SB-c-m-font-style: normal;
  --caption-m-SB-c-m-font-weight: 500;
  --caption-m-SB-c-m-letter-spacing: 0px;
  --caption-m-SB-c-m-line-height: 16px;
  --collection-BG-100: rgba(44, 44, 44, 1);
  --collection-BG-200: rgba(32, 32, 32, 1);
  --collection-BG-300: rgba(34, 34, 34, 1);
  --collection-BG-400: rgba(35, 35, 35, 1);
  --collection-BG-500: rgba(38, 38, 38, 1);
  --collection-bg-600: rgba(41, 41, 41, 1);
  --collection-BG-base: rgba(27, 28, 30, 1);
  --confirmation-color-CC-l-150: rgba(48, 172, 121, 1);
  --confirmation-color-CC-l-450: rgba(121, 201, 168, 1);
  --error-color-EC-l-300: rgba(242, 108, 110, 1);
  --error-color-EC-l-450: rgba(245, 139, 141, 1);
  --heading-SB-h4-font-family: "SF Pro Display", Helvetica;
  --heading-SB-h4-font-size: 20px;
  --heading-SB-h4-font-style: normal;
  --heading-SB-h4-font-weight: 400;
  --heading-SB-h4-letter-spacing: 0px;
  --heading-SB-h4-line-height: 24px;
  --info-color-IC-base: rgba(58, 112, 226, 1);
  --info-color-IC-d-300: rgba(41, 78, 158, 1);
  --info-color-IC-l-150: rgba(88, 133, 230, 1);
  --info-color-IC-l-300: rgba(117, 155, 235, 1);
  --info-color-IC-l-450: rgba(147, 176, 239, 1);
  --info-color-IC-l-600: rgba(176, 198, 243, 1);
  --neutral-color-grey-1000: rgba(250, 250, 250, 1);
  --neutral-color-grey-150: rgba(58, 60, 62, 1);
  --neutral-color-grey-300: rgba(93, 94, 96, 1);
  --neutral-color-grey-450: rgba(127, 128, 130, 1);
  --neutral-color-grey-600: rgba(162, 163, 164, 1);
  --neutral-color-grey-750: rgba(197, 197, 198, 1);
  --neutral-color-grey-900: rgba(232, 232, 232, 1);
  --neutral-color-grey-950: rgba(243, 243, 244, 1);
  --neutral-color-grey-base: rgba(23, 25, 28, 1);
  --paragraph-m-p1-font-family: "SF Pro Text", Helvetica;
  --paragraph-m-p1-font-size: 16px;
  --paragraph-m-p1-font-style: normal;
  --paragraph-m-p1-font-weight: 500;
  --paragraph-m-p1-letter-spacing: 0px;
  --paragraph-m-p1-line-height: 24px;
  --paragraph-m-p2-font-family: "SF Pro Text", Helvetica;
  --paragraph-m-p2-font-size: 14px;
  --paragraph-m-p2-font-style: normal;
  --paragraph-m-p2-font-weight: 500;
  --paragraph-m-p2-letter-spacing: 0px;
  --paragraph-m-p2-line-height: 20px;
  --paragraph-m-p3-font-family: "SF Pro Text", Helvetica;
  --paragraph-m-p3-font-size: 12px;
  --paragraph-m-p3-font-style: normal;
  --paragraph-m-p3-font-weight: 500;
  --paragraph-m-p3-letter-spacing: 0px;
  --paragraph-m-p3-line-height: 18px;
  --paragraph-r-p1-font-family: "SF Pro Text", Helvetica;
  --paragraph-r-p1-font-size: 16px;
  --paragraph-r-p1-font-style: normal;
  --paragraph-r-p1-font-weight: 400;
  --paragraph-r-p1-letter-spacing: 0px;
  --paragraph-r-p1-line-height: 24px;
  --paragraph-r-p2-font-family: "SF Pro Text", Helvetica;
  --paragraph-r-p2-font-size: 14px;
  --paragraph-r-p2-font-style: normal;
  --paragraph-r-p2-font-weight: 400;
  --paragraph-r-p2-letter-spacing: 0px;
  --paragraph-r-p2-line-height: 20px;
  --paragraph-r-p3-font-family: "SF Pro Text", Helvetica;
  --paragraph-r-p3-font-size: 12px;
  --paragraph-r-p3-font-style: normal;
  --paragraph-r-p3-font-weight: 400;
  --paragraph-r-p3-letter-spacing: 0px;
  --paragraph-r-p3-line-height: 18px;
  --paragraph-SB-p1-font-family: "SF Pro Text", Helvetica;
  --paragraph-SB-p1-font-size: 16px;
  --paragraph-SB-p1-font-style: normal;
  --paragraph-SB-p1-font-weight: 400;
  --paragraph-SB-p1-letter-spacing: 0px;
  --paragraph-SB-p1-line-height: 24px;
  --paragraph-SB-p2-font-family: "SF Pro Text", Helvetica;
  --paragraph-SB-p2-font-size: 14px;
  --paragraph-SB-p2-font-style: normal;
  --paragraph-SB-p2-font-weight: 400;
  --paragraph-SB-p2-letter-spacing: 0px;
  --paragraph-SB-p2-line-height: 20px;
  --paragraph-SB-p3-font-family: "SF Pro Text", Helvetica;
  --paragraph-SB-p3-font-size: 12px;
  --paragraph-SB-p3-font-style: normal;
  --paragraph-SB-p3-font-weight: 400;
  --paragraph-SB-p3-letter-spacing: 0px;
  --paragraph-SB-p3-line-height: 18px;
  --white-color-white: rgba(255, 255, 255, 1);
  --neutral-color-grey-900: rgba(232, 232, 232, 1);
  --heading-SB-h5-font-family: "SF Pro Text", Helvetica;
  --heading-SB-h5-font-size: 18px;
  --heading-SB-h5-font-style: normal;
  --heading-SB-h5-font-weight: 400;
  --heading-SB-h5-letter-spacing: 0px;
  --heading-SB-h5-line-height: 22px;
  --confirmation-color-CC-base: rgba(12, 157, 97, 1);
  --confirmation-color-CC-l-950: rgba(243, 250, 247, 1);
  --collection-BG-500: rgba(38, 38, 38, 1);
  --confirmation-color-CC-l-150: rgba(48, 172, 121, 1);
  --error-color-EC-l-150: rgba(239, 77, 79, 1);
  --heading-m-h2-font-family: "SF Pro Display", Helvetica;
  --heading-m-h2-font-size: 28px;
  --heading-m-h2-font-style: normal;
  --heading-m-h2-font-weight: 500;
  --heading-m-h2-letter-spacing: 0px;
  --heading-m-h2-line-height: 32px;
  --heading-SB-h3-font-family: "SF Pro Display", Helvetica;
  --heading-SB-h3-font-size: 24px;
  --heading-SB-h3-font-style: normal;
  --heading-SB-h3-font-weight: 400;
  --heading-SB-h3-letter-spacing: 0px;
  --heading-SB-h3-line-height: 28px;
  --warning-color-2-WC-l-150: rgba(255, 119, 56, 1);
  --confirmation-color-CC-d-150: rgba(10, 133, 82, 1);
  --confirmation-color-CC-l-150: rgba(48, 172, 121, 1);
  --error-color-EC-d-150: rgba(201, 38, 41, 1);

  --caption-m-SB-c-SB-font-family: "SF Pro Text", Helvetica;
  --caption-m-SB-c-SB-font-size: 11px;
  --caption-m-SB-c-SB-font-style: normal;
  --caption-m-SB-c-SB-font-weight: 400;
  --caption-m-SB-c-SB-letter-spacing: 0px;
  --caption-m-SB-c-SB-line-height: 16px;
  --secondary-color-SC-l-150: #d4e028;
  --collection-BG-selected: rgba(58, 62, 69, 1);
  /* fonts */
  --paragraph-r-p3: "SF Pro Display", Helvetica;
  --heading-m-h4: "SF Pro Display", Helvetica;

  /* font sizes */
  --paragraph-r-p3-size: 12px;
  --heading-m-h4-size: 20px;
  --heading-m-h5-size: 18px;

  /* Colors */
  --grey-600: #a2a3a4;
  --ec-l-450: #f58b8d;
  --grey-900: #e8e8e8;

  /* Gaps */
  --gap-5xs: 8px;

  /* Paddings */
  --padding-8xs-5: 4.5px;
  --padding-5xs: 8px;
}

/*

  To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

  <body data-neutral-color-mode="light">
      <!-- the rest of your content -->
  </body>

  You can apply the theme on any DOM node, not just the `body`

  */

[data-neutral-color-mode="light"] {
  --neutral-color-grey-950: rgba(243, 243, 244, 1);
}

[data-neutral-color-mode="dark"] {
  --neutral-color-grey-950: rgba(44, 44, 44, 1);
}

/*

  To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

  <body data-neutral-color-mode="light">
      <!-- the rest of your content -->
  </body>

  You can apply the theme on any DOM node, not just the `body`

  */

[data-neutral-color-mode="light"] {
  --neutral-color-grey-1000: rgba(250, 250, 250, 1);
  --neutral-color-grey-900: rgba(232, 232, 232, 1);
}

[data-neutral-color-mode="dark"] {
  --neutral-color-grey-1000: rgba(44, 44, 44, 1);
  --neutral-color-grey-900: rgba(93, 94, 96, 1);
}

.nav-icon {
  overflow: visible !important;
  word-break: break-all;
}

.app-side-nav {
  background-color: #2c2c2c;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  padding-top: 24px;
  gap: 8px;
}

.sidebar-nav .nav-link.active {
  border-radius: 4px;
  background: #3a3c3e;
  color: white;
}

.sidebar-nav .nav-link {
  cursor: pointer;
}

body {
  background: #1b1c1e;
}

.menu-links {
  cursor: pointer;
}

input:-internal-autofill-selected {
  background-color: var(--collection-BG-500) !important;
  appearance: auto !important;
}

::-webkit-scrollbar {
  width: 8px;
}

/*
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
  } */

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 4px;
}
/*
::-webkit-scrollbar-track {
   background-color: #f0f0f0;
} */

,
.security-score-label {
  fill: var(--neutral-color-grey-600);
  font-family: var(--heading-m-h5-font-family);
  font-size: 12px;
}

.security-score-value {
  fill: var(--neutral-color-grey-1000);
  font-family: var(--heading-m-h4-font-family);
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}

.security-score-value span {
  font-size: 16px;
  line-height: 24px;
}

.loading-text,
.loading-dot {
  font-size: 22px !important;
  color: rgb(187 171 171);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
  caret-color: white;
}

@keyframes dot-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-dot {
  animation: dot-blink 1.5s infinite;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.3s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.6s;
}

.canvasjs-chart-credit {
  display: none !important;
}
