.container {
  align-items: flex-start;
  border-radius: 4px 4px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  position: relative;
  width: 400px;
}

:global(.MuiDrawer-modal) .remediations-side-drawer {
  background-color: var(--collection-BG-base);
  overflow: hidden;
}

.content-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
}

.header {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--collection-BG-base);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #ffffff14;
  border-left-style: none;
  border-radius: 4px 4px 0px 0px;
  border-right-style: none;
  border-top-style: none;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  padding: 24px 16px 16px;
  position: relative;
  width: 100%;
}

.title-close {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.title {
  color: var(--neutral-color-grey-950);
  font-family: "SF Pro Display-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.link-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.date {
  color: var(--neutral-color-grey-600);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.ellipse {
  background-color: var(--neutral-color-grey-600);
  border-radius: 2px;
  height: 4px;
  position: relative;
  width: 4px;
}

.view-report {
  color: var(--info-color-IC-l-150);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.icons-4 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.actions {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
}

.button-instance,
.button-disabled-instance {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  text-transform: none !important;
}

.button-disabled-instance {
  background-color: #20335e !important;
  color: #959595 !important;
}

.btncontainer {
  align-items: flex-end;
  align-self: stretch;
  background-color: var(--collection-BG-base);
  border-color: var(--black-10);
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0px;
  position: relative;
  width: 100%;
  background: #1b1c1e;
}

.div-2 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-color: #ffffff0a;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  margin-bottom: -4px;
  margin-left: -4px;
  margin-right: -4px;
  margin-top: -4px;
  padding: 8px 0px;
  position: relative;
  width: 100%;
}

.single-content-text {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
  position: relative;
  width: 100%;
}

.issue {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
  width: 100%;
}

.icon-instance-node {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
  cursor: pointer !important;
}

.body-text {
  color: var(--neutral-color-grey-600);
  flex: 1;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
}

.body-text-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 0px 0px 28px;
  position: relative;
  width: 100%;
}

.text-wrapper {
  color: var(--neutral-color-grey-750);
  flex: 1;
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -1px;
  position: relative;
}

.body-text-2 {
  color: var(--error-color-EC-l-150);
  flex: 1;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
}

.single-content-text-2 {
  align-items: flex-start;
  align-self: stretch;
  border-color: var(--confirmation-color-CC-l-150);
  border-left-style: solid;
  border-left-width: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
  position: relative;
  width: 100%;
}

.body-text-3 {
  color: var(--confirmation-color-CC-l-150);
  flex: 1;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
}

.text-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  padding: 0px 0px 0px 28px;
  position: relative;
  width: 100%;
}

.p {
  align-self: stretch;
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -1px;
  position: relative;
}
