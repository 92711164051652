.section-screen {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  overflow-x: auto;
}

.section {
  align-items: flex-start;
  display: flex;
  /* gap: 20px; */
  position: relative;
  width: 100%;
  flex-direction: row;
}

.card {
  align-items: flex-start;
  background-color: var(--collection-BG-500);
  border: 1px solid;
  border-color: var(--neutral-color-grey-base);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  width: 100%;
}

.emptycard {
  align-items: flex-start;
  /* background-color: var(--collection-BG-500); */
  /* border: 1px solid; */
  /* border-color: var(--neutral-color-grey-base); */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  width: 100%;
}

.head {
  align-items: flex-start;
  align-self: stretch;
  background-color: #0000000a;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #ffffff0a;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.head .top {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  padding: 16px;
  position: relative;
  width: 100%;
}

.head .title {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  justify-content: center;
  position: relative;
  overflow: auto;
}

.head .domin-logo {
  background-color: var(--neutral-color-grey-150);
  border-radius: 99px;
  height: 54px;
  overflow: auto;
  position: relative;
  width: 54px;
}

.head .icon-placeholder-instance {
  /* left: 11px !important; */
  top: 11px !important;
}

.head .icon-instance-node {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.head .div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
  width: 100%;
}

.head .access-control {
  align-self: stretch;
  color: var(--neutral-color-grey-750);
  font-family: var(--heading-m-h4-font-family);
  font-size: var(--heading-m-h4-font-size);
  font-style: var(--heading-m-h4-font-style);
  font-weight: var(--heading-m-h4-font-weight);
  letter-spacing: var(--heading-m-h4-letter-spacing);
  line-height: var(--heading-m-h4-line-height);
  margin-top: -1px;
  position: relative;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden */
}

.head .text-wrapper-2 {
  align-self: stretch;
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  position: relative;
}

.head .graph {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.head .half-doughnut-chart-instance {
  position: relative !important;
}

.head .design-component-instance-node {
  background-image: url(https://c.animaapp.com/TVGidtmS/img/track-6.svg) !important;
}

.head .half-doughnut-chart-2 {
  height: 80px !important;
  left: 0 !important;
  width: 80px !important;
}

.sectioningraph {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 1.96px;
  justify-content: center;
  position: absolute;
  top: 32px;
  left: 24px;
}

.valueingraph {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.96px;
  justify-content: center;
  position: relative;
}

.elementingrapgh {
  color: var(--neutral-color-grey-950);
  font-family: "SF Pro Display-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -0.49px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.spaningraph {
  color: #f3f3f4;
  font-family: var(--heading-SB-h4-font-family);
  font-size: var(--heading-SB-h4-font-size);
  font-style: var(--heading-SB-h4-font-style);
  font-weight: var(--heading-SB-h4-font-weight);
  letter-spacing: var(--heading-SB-h4-letter-spacing);
  line-height: var(--heading-SB-h4-line-height);
}

.text-wrapper-graph {
  font-family: var(--paragraph-r-p1-font-family);
  font-size: var(--paragraph-r-p1-font-size);
  font-style: var(--paragraph-r-p1-font-style);
  font-weight: var(--paragraph-r-p1-font-weight);
  letter-spacing: var(--paragraph-r-p1-letter-spacing);
  line-height: var(--paragraph-r-p1-line-height);
}

.head .section-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 16px 16px;
  position: relative;
  width: 100%;
}

.head .section-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 12px;
  position: relative;
}

.head .controls {
  align-items: center;
  border-radius: 32px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.head .icon-content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  position: relative;
}

.head .icon-placeholder-2 {
  height: 24px !important;
  width: 24px !important;
}

.head .text-wrapper-3 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.head .score {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
}

.head .element-2 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-SB-p1-font-family);
  font-size: var(--paragraph-SB-p1-font-size);
  font-style: var(--paragraph-SB-p1-font-style);
  font-weight: var(--paragraph-SB-p1-font-weight);
  letter-spacing: var(--paragraph-SB-p1-letter-spacing);
  line-height: var(--paragraph-SB-p1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.head .rectangle {
  background-color: #ffffff14;
  border-radius: 2px;
  height: 16px;
  position: relative;
  width: 1px;
}

.content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  position: relative;
  width: 100%;
}

.content .item {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.content .icons-2 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.content .level {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  padding: 1px 0px 0px;
  position: relative;
}

.content .p {
  align-self: stretch;
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  margin-top: -1px;
  position: relative;
}

.content .divider {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  padding: 0px 0px 0px 28px;
  position: relative;
  width: 100%;
}

.content .line {
  background-color: #ffffff0a;
  flex: 1;
  flex-grow: 1;
  height: 1px;
  position: relative;
}

.content .view-detail-button {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: flex-end;
  padding: 8px 0px 0px;
  position: relative;
  width: 100%;
}

.content .button {
  align-items: center;
  border-radius: 6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  height: 34px;
  justify-content: center;
  overflow: auto;
  position: relative;
}

.content .text {
  color: var(--info-color-IC-l-300);
  font-family: var(--paragraph-m-p2-font-family);
  font-size: var(--paragraph-m-p2-font-size);
  font-style: var(--paragraph-m-p2-font-style);
  font-weight: var(--paragraph-m-p2-font-weight);
  letter-spacing: var(--paragraph-m-p2-letter-spacing);
  line-height: var(--paragraph-m-p2-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
