.warning {
  border-bottom: 1px solid var(--WC-L-150, #ff7738);
  background: linear-gradient(
      270deg,
      #262626 0%,
      rgba(38, 38, 38, 0.04) 20%,
      rgba(38, 38, 38, 0.04) 80%,
      #262626 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 187, 56, 0) 52%,
      rgba(255, 187, 56, 0.08) 84%,
      rgba(255, 187, 56, 0.24) 100%
    );
  display: flex;
  /* height: 120px; */
  padding: 0px 12px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
}

.success {
  border-bottom: 1px solid var(--CC-L-150, #30ac79);
  background: linear-gradient(
      270deg,
      #262626 0%,
      rgba(38, 38, 38, 0.04) 20%,
      rgba(38, 38, 38, 0.04) 80%,
      #262626 100%
    ),
    linear-gradient(
      180deg,
      rgba(48, 172, 121, 0) 52%,
      rgba(48, 172, 121, 0.08) 84%,
      rgba(48, 172, 121, 0.24) 100%
    );
  display: flex;
  /* height: 120px; */
  padding: 0px 12px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
}

.error {
  border-bottom: 1px solid var(--EC-L-150, #ef4d4f);
  background: linear-gradient(
      270deg,
      #262626 0%,
      rgba(38, 38, 38, 0.04) 20%,
      rgba(38, 38, 38, 0.04) 80%,
      #262626 100%
    ),
    linear-gradient(
      180deg,
      rgba(239, 77, 79, 0) 52%,
      rgba(239, 77, 79, 0.08) 84%,
      rgba(239, 77, 79, 0.24) 100%
    );
  display: flex;
  /* height: 120px; */
  padding: 0px 12px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
}

.mostlyeffective {
  border-bottom: 1px solid var(--SC-L-150, #d4e028);
  background: linear-gradient(
      270deg,
      #262626 0%,
      rgba(38, 38, 38, 0.04) 20%,
      rgba(38, 38, 38, 0.04) 80%,
      #262626 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 187, 56, 0) 52%,
      rgba(255, 187, 56, 0.08) 84%,
      rgba(255, 187, 56, 0.24) 100%
    );
  display: flex;
  /* height: 120px; */
  padding: 0px 12px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
}

.text-wrapper-warning {
  color: var(--warning-color-2-WC-l-150);
  font-family: var(--heading-SB-h3-font-family);
  font-size: var(--heading-SB-h3-font-size);
  font-style: var(--heading-SB-h3-font-style);
  font-weight: var(--heading-SB-h3-font-weight);
  letter-spacing: var(--heading-SB-h3-letter-spacing);
  line-height: var(--heading-SB-h3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-success {
  color: var(--confirmation-color-CC-l-150);
  font-family: var(--heading-SB-h3-font-family);
  font-size: var(--heading-SB-h3-font-size);
  font-style: var(--heading-SB-h3-font-style);
  font-weight: var(--heading-SB-h3-font-weight);
  letter-spacing: var(--heading-SB-h3-letter-spacing);
  line-height: var(--heading-SB-h3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-error {
  color: var(--error-color-EC-l-150);
  font-family: var(--heading-SB-h3-font-family);
  font-size: var(--heading-SB-h3-font-size);
  font-style: var(--heading-SB-h3-font-style);
  font-weight: var(--heading-SB-h3-font-weight);
  letter-spacing: var(--heading-SB-h3-letter-spacing);
  line-height: var(--heading-SB-h3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-mostlyeffective {
  color: var(--secondary-color-SC-l-150);
  font-family: var(--heading-SB-h3-font-family);
  font-size: var(--heading-SB-h3-font-size);
  font-style: var(--heading-SB-h3-font-style);
  font-weight: var(--heading-SB-h3-font-weight);
  letter-spacing: var(--heading-SB-h3-letter-spacing);
  line-height: var(--heading-SB-h3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-caption {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-m-p2-font-family);
  font-size: var(--paragraph-m-p2-font-size);
  font-style: var(--paragraph-m-p2-font-style);
  font-weight: var(--paragraph-m-p2-font-weight);
  letter-spacing: var(--paragraph-m-p2-letter-spacing);
  line-height: var(--paragraph-m-p2-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.span {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 0px 3px 0px 0px;
  position: relative;
}
