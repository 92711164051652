.modal {
  align-items: center;
  background-color: var(--collection-BG-500);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 90%; */
  /* overflow-y: auto; */
}

.title {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #ffffff14;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0px 24px;
  position: relative;
  width: 100%;
}

.title .title-wrapper {
  color: var(--neutral-color-grey-950);
  font-family: var(--heading-m-h4-font-family);
  font-size: var(--heading-m-h4-font-size);
  font-style: var(--heading-m-h4-font-style);
  font-weight: var(--heading-m-h4-font-weight);
  letter-spacing: var(--heading-m-h4-letter-spacing);
  line-height: var(--heading-m-h4-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.title .icons-crossmark {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.div-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.tab-navigation {
  align-items: center;
  align-self: stretch;
  background-color: var(--collection-BG-200);
  border-color: #ffffff14;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 24px;
  position: relative;
  width: 250px;
}

.tab-navigation .tabs-instance {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.section-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  justify-content: center;
  padding: 24px;
  position: relative;
  /* overflow-y: auto; */
}

.section-1 .title-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  margin-top: -2.5px;
  padding: 0px 0px 12px;
  position: relative;
  width: 100%;
}

.section-1 .title-2-wrapper {
  color: var(--neutral-color-grey-950);
  font-family: var(--heading-m-h5-font-family);
  font-size: var(--heading-m-h5-font-size);
  font-style: var(--heading-m-h5-font-style);
  font-weight: var(--heading-m-h5-font-weight);
  letter-spacing: var(--heading-m-h5-letter-spacing);
  line-height: var(--heading-m-h5-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chart-content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chart {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 54px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chart .graph {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.chart .half-doughnut-chart {
  height: 200px;
  position: relative;
  width: 200px;
}

.chart .section-2 {
  align-items: center;
  border: 1px solid;
  border-color: #ffffff14;
  border-radius: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.chart .controlstested {
  align-items: center;
  background-color: #0000000a;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #ffffff14;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 429px;
}

.chart .icon-content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  position: relative;
}

.chart .text-wrapper-control {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p1-font-family);
  font-size: var(--paragraph-r-p1-font-size);
  font-style: var(--paragraph-r-p1-font-style);
  font-weight: var(--paragraph-r-p1-font-weight);
  letter-spacing: var(--paragraph-r-p1-letter-spacing);
  line-height: var(--paragraph-r-p1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chart .score {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
}

.chart .control-value {
  color: var(--neutral-color-grey-950);
  font-family: var(--paragraph-SB-p1-font-family);
  font-size: var(--paragraph-SB-p1-font-size);
  font-style: var(--paragraph-SB-p1-font-style);
  font-weight: var(--paragraph-SB-p1-font-weight);
  letter-spacing: var(--paragraph-SB-p1-letter-spacing);
  line-height: var(--paragraph-SB-p1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chart .sub-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chart .controls {
  align-items: flex-start;
  border-radius: 32px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  justify-content: center;
  padding: 12px;
  position: relative;
}

.chart .icon-instance-node {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.chart .controlvalues {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: flex-end;
  padding: 0px 0px 0px 26px;
  position: relative;
}

.chart .divider {
  background-color: #ffffff14;
  border-radius: 2px;
  height: 48px;
  position: relative;
  width: 1px;
}

.section {
  align-items: flex-start;
  background-color: var(--collection-BG-300);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  position: relative;
  width: 100%;
  /* overflow-y: auto; */
}

.section .border {
  align-self: stretch;
  background-color: #ffffff14;
  height: 1px;
  position: relative;
  width: 100%;
}

.section .access-control {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  /* overflow-y: auto; */
  position: relative;
  width: 100%;
}

.section .div {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.section .access-control-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.section .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.section .title-content {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.section .text-wrapper {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-SB-p1-font-family);
  font-size: var(--paragraph-SB-p1-font-size);
  font-style: var(--paragraph-SB-p1-font-style);
  font-weight: var(--paragraph-SB-p1-font-weight);
  letter-spacing: var(--paragraph-SB-p1-letter-spacing);
  line-height: var(--paragraph-SB-p1-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.section .text-wrapper-2 {
  color: var(--neutral-color-grey-600);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.section .icon-instance-node {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.section .line {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.section .title-content-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.div-collpase {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  position: relative;
}

.div-wrapper {
  /* align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column; */
  gap: 4px;
  /* position: relative; */
}

.div-wrapper .text-icon-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.div-wrapper .icons-6 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.div-wrapper .text-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.div-wrapper .text-wrapper-4 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin-bottom: 0px;
}

.div-wrapper .text-wrapper-5 {
  color: var(--neutral-color-grey-600);
  font-family: var(--paragraph-SB-p1-font-family);
  font-size: var(--paragraph-SB-p1-font-size);
  font-style: var(--paragraph-SB-p1-font-style);
  font-weight: var(--paragraph-SB-p1-font-weight);
  letter-spacing: var(--paragraph-SB-p1-letter-spacing);
  line-height: var(--paragraph-SB-p1-line-height);
  position: relative;
  width: 259px;
}
