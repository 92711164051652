html {
  width: 100%;
  /* font-family: "Lato", sans-serif !important; */
}

input[type="text"],
input[type="search"],
input[type="email"],
input[type="password"] {
  /* font-family: "Lato", sans-serif !important; */
  font-size: 14.5px !important;
}

table.datatable thead > tr > th {
  font-size: 12.5px !important;
  /* font-family: "Lato", sans-serif !important; */
}

table.datatable tbody > tr > td,
table.datatable tbody > tr > td a {
  font-size: 12.5px !important;
  /* font-family: "Lato", sans-serif !important; */
}

label.control-label,
.pink-btn,
#datatable-columns-settings-wrapper button,
.top-bar .searchbox input[type="search"],
.logged-user .dropdown-menu li a,
#left-sidebar ul.main-menu li > a,
#left-sidebar ul.main-menu li > a span,
.breadcrumb li.active,
.breadcrumb li.active span,
p,
div {
  font-size: 12.5px;
  /* font-family: "Lato", sans-serif !important; */
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-select,
.MuiTablePagination-displayedRows {
  font-size: 12.5px !important;
  margin-bottom: 0px !important;
  /* font-family: "Lato", sans-serif !important; */
}

.MuiDataGrid-columnSeparator--sideRight {
  visibility: initial !important;
}

@media screen and (min-width: 576px) {
  .right-align {
    text-align: right;
    padding-right: 0px;
  }
}

.no-suggestions {
  color: #171811;
  padding: 0.5rem;
}

.suggestions {
  position: absolute;
  border: 1px solid #171811;
  list-style: none;
  margin-top: 0;
  /* max-height: 143px; */
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  background: white;
  margin-top: 37px;
  z-index: 99;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestions li:hover {
  background-color: #d1d900;
  cursor: pointer;
  font-weight: 700;
  color: #171811;
}

.col-form-label.required:before {
  content: "*";
  color: red;
  margin-right: 3px;
}

.form-checkbox {
  vertical-align: -webkit-baseline-middle;
}

.form-label-control {
  padding-left: 0px;
}

.vendor-contact-list-grid-container {
  height: calc(100vh - 273px);
  width: 100%;
}

.notes-grid {
  height: 300px;
  width: 100%;
}

.activity-grid {
  height: 400px;
  width: 100%;
}

.vendor-manager-modal {
  max-width: 980px !important;
}

.post-btn-row {
  margin-top: 12px;
  margin-right: 0px;
  margin-bottom: 12px;
}

.loading-indicator:before {
  content: "";
  background-color: rgba(255, 255, 255, 0.2);
  /* backdrop-filter: blur(1px); */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1099;
}

/*
.loading-indicator:after {
    content: 'Loading';
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.5rem;
} */

.loader {
  color: #495057;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  z-index: 1100;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: fixed;
  top: 30%;
  left: 46%;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.notes-custom {
  width: 12.5%;
}

.vendor-contacts-submit-grid {
  height: 300px;
  width: 100%;
  margin-top: 12px;
}

/*
.vendor-submit-stepper a{
    margin-top: 0px !important;
    font-size: 14px !important;
} */

.vendor-submit-stepper {
  margin-bottom: 24px;
}

.vendor-submit-stepper > div > div > div > div:first-child {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.vendor-contacts-submit-sections-grid {
  margin-top: 12px;
  height: 100%;
  height: 300px;
}

.vendor-contacts-submit-sections-grid .MuiDataGrid-footerContainer {
  display: none;
}

.cirlce-names {
  height: 28px;
  width: 30px;
  background-color: rgb(58, 60, 62);
  border-radius: 50%;
  display: inline-block;
  font-weight: 500;
  padding-top: 5px;
  cursor: default;
  margin-right: 5px;
  color: #cedbf8;
}

.error-assessment-container::-webkit-scrollbar {
  width: 0px !important;
}

.logo-img {
  max-width: 100%;
  max-height: 100%;
  margin-top: -6px;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.vendor-exchange-grid-container {
  height: calc(100vh - 150px);
  width: 100%;
}

.vendor-exchange-assessments-container {
  height: calc(100vh - 311px);
  width: 100%;
}

.vendor-details-card {
  width: 100%;
  /* height: calc(100vh - 170px); */
  padding-right: 10px;
}

.tabs-container {
  margin-top: 24px;
}

.tabs-container .nav-tabs .nav-link.active {
  font-weight: 600;
}

.tabs-container .nav-tabs .nav-link {
  color: #495057 !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  overflow: visible !important;
}

.progress-status-bar {
  border-radius: 10px;
  height: 8px;
}

.progress-status-bar .progress-bar {
  width: 100%;
  font-size: 10px;
}

.progress-status-bar-red .progress-bar {
  background-color: #00b0f0 !important;
}

.progress-status-bar-green .progress-bar {
  background-color: green !important;
}

.assesment-count {
  text-transform: none;
  font-weight: bold;
  background-color: rgb(58, 60, 62) !important;
  color: #cedbf8 !important;
}

.grid-status-bar {
  text-transform: none;
  color: black;
  font-weight: inherit;
  width: 100px;
  font-size: 12px;
}

.status-bar-inprogress {
  background-color: #00b0f0 !important;
}

.status-bar-returned {
  background-color: #ffc000 !important;
}

.status-bar-completed {
  background-color: #92d050 !important;
}

.status-bar-due-past {
  background-color: red !important;
}

.status-bar-new {
  background-color: #f9e2ac !important;
  color: black;
}

.cursor-pointer {
  cursor: pointer !important;
}

.file-drop-zone {
  display: grid;
  place-content: center;
  border: 4px dashed rgb(211 211 211 / 40%);
  padding: 30px 10px;
  text-align: center;
  border-radius: 16px;
}

.file-label {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 8px;
  font-weight: normal;
}

.df-color {
  color: #628902;
}

.svg-inline--fa {
  vertical-align: -0.325em !important;
  padding-left: 4px;
}

.custom-check-box {
  width: 16px;
  height: 16px;
  margin-top: 0px;
}

.btn {
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  border-color: #628902 !important;
  font-family: var(--bs-font-sans-serif);
}

.scrollbar-container .ps__thumb-y {
  background-color: #b9bcbe;
}

.scrollbar-container {
  padding-right: 12px;
}

.user-icon-toggle {
  border: none;
}

.auto-complete-caret svg {
  padding-left: 0px;
}

.auto-complete-caret {
  border-radius: 4px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #171811 inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: white;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #171811 inset;
  /*your box-shadow*/
  -webkit-text-fill-color: white;
}

.back-button-svg:focus {
  outline: none !important;
}

.eye-button svg:focus {
  outline: none !important;
}

.MuiDataGrid-iconSeparator {
  color: #628902 !important;
}

[data-initials]:before {
  background: #fff;
  color: #171811;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border: 2px solid #d1d900;
  text-align: center;
}

.nav-link-icon-1 {
  float: right;
  margin-top: 3px;
  font-size: 1rem;
  width: 30px;
}

.sections-card-body {
  overflow-y: auto;
  height: calc(100vh - 245px);
  padding-right: 0px;
}

.sections-span-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 75%;
  display: inline-block;
}

.override-name {
  text-decoration: line-through !important;
}

.inside-app-header {
  padding-top: 10px;
  padding-left: 10px;
}

.section-heading {
  font-size: 0.88rem;
  font-weight: 600;
}

.remarks-textarea {
  width: 50% !important;
}

.documents-remarks-textarea {
  width: 90% !important;
}

@media (max-width: 1260px) {
  .vendor-exchange-grid-container {
    min-height: 300px;
  }

  .vendor-exchange-assessments-container {
    min-height: 300px;
  }

  .questionnaire-card {
    min-height: 300px;
  }

  .page-title-actions {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 27px;
  }
}

@media (max-width: 767.98px) {
  .app-page-title .page-title-actions {
    margin-top: 27px;
  }

  .app-page-title {
    text-align: left;
  }
}

.name-heading {
  text-align: center;
  font-weight: 600 !important;
  font-size: 14px;
}

.date-col {
  /* width: 32%; */
  padding: 0px;
}

.inline-p {
  display: inline-block;
  font-size: 10px;
  margin: 5px 0px;
}

.inline-text {
  font-size: 10px;
}

.file-name-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remediation-details-action-dropdown .dropdown-menu {
  min-width: 190px;
}
.remediation-details-action-dropdown .dropdown-menu::before,
.remediation-details-action-dropdown .dropdown-menu::after {
  left: 146px !important;
}

.remediation-evidence-icon:focus {
  outline: none !important;
}

.input-group .input-box {
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid #ccc;
  padding: 11px;
  user-select: none;
}

.input-group .input-box .placeholder {
  color: #a28e8e;
  font-weight: unset;
}

.input-group .input-box label {
  margin-bottom: 0px !important;
}

.vertical-timeline::before {
  background: #dee2e6 !important;
}
