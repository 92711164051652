.section-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.y-axis {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 220px;
  justify-content: space-between;
  position: relative;
  padding-right: 16px;
  border-right: 1px solid;
  border-color: var(--neutral-color-grey-150);
}

.y-axis .div {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-m-p2-font-family);
  font-size: var(--paragraph-m-p2-font-size);
  font-style: var(--paragraph-m-p2-font-style);
  font-weight: var(--paragraph-m-p2-font-weight);
  letter-spacing: var(--paragraph-m-p2-letter-spacing);
  line-height: var(--paragraph-m-p2-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.axis-line {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 220px;
  justify-content: space-between;
  width: 80%;
}

.metric-card {
  /* align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative; */
  width: 100%;
}

.metric-card .text-wrapper {
  align-self: stretch;
  color: var(--neutral-color-grey-750);
  font-family: var(--caption-m-SB-c-m-font-family);
  font-size: var(--caption-m-SB-c-m-font-size);
  font-style: var(--caption-m-SB-c-m-font-style);
  font-weight: var(--caption-m-SB-c-m-font-weight);
  letter-spacing: var(--caption-m-SB-c-m-letter-spacing);
  line-height: var(--caption-m-SB-c-m-line-height);
  margin-top: -10px;
  position: relative;
  top: -4px;
  width: fit-content;
}

.metric-card .progress-bar {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.metric-card .overlap-group-wrapper {
  background-color: var(--neutral-color-grey-150);
  border-radius: 8px;
  flex: 1;
  flex-grow: 1;
  height: 1px;
  position: relative;
}

.metric-card .overlap-group {
  height: 18px;
  position: relative;
  top: -5px;
  /* width: 174px; */
}

.metric-card .vendor-score {
  background-color: var(--info-color-IC-l-150);
  border-radius: 4px 4px 4px 4px;
  height: 2px;
  left: 0;
  position: absolute;
  top: 0px;
  /* width: 174px; */
}

.metric-card .vendor-score-circle {
  background-color: var(--info-color-IC-l-150);
  border-radius: 12px;
  height: 16px;
  position: relative;
  width: 16px;
  top: -7px;
}

.metric-card .industry-score-gap {
  background-color: var(--neutral-color-grey-150);
  /* background-color: var(--warning-color-2-WC-l-150); */
  border-radius: 4px 4px 4px 4px;
  border: 1px dashed var(--warning-color-2-WC-l-150);
  /* border-style: dotted; */
  height: 2px;
  left: 0;
  position: absolute;
  top: 0px;
}

.metric-card .industry-score-circle {
  background-color: var(--warning-color-2-WC-l-150);
  border-radius: 12px;
  height: 10px;
  position: relative;
  width: 10px;
  top: -20px;
}

.metric-card .percentage {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-SB-p3-font-family);
  font-size: var(--paragraph-SB-p3-font-size);
  font-style: var(--paragraph-SB-p3-font-style);
  font-weight: var(--paragraph-SB-p3-font-weight);
  letter-spacing: var(--paragraph-SB-p3-letter-spacing);
  line-height: var(--paragraph-SB-p3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.metric-card .text-wrapper-2 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-m-p2-font-family);
  font-size: var(--paragraph-m-p2-font-size);
  font-style: var(--paragraph-m-p2-font-style);
  font-weight: var(--paragraph-m-p2-font-weight);
  letter-spacing: var(--paragraph-m-p2-letter-spacing);
  line-height: var(--paragraph-m-p2-line-height);
  height: 16px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
}
