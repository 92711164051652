.heading {
    color: #0C9D61;
}

.header {
    display: flex;
}

.snackcontent {
    background-color: #2C2C2C;
    border-radius: 8px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    padding: 16px;
}

.body {
    margin-left: 30px;
}