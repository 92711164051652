.icon-placeholder {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2px;
  position: relative;
}

.icon-placeholder .icons-4 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.icon-placeholder.twenty-x-20 {
  height: 20px;
  width: 20px;
}

.icon-placeholder.twenty-four-x-24 {
  height: 24px;
  width: 24px;
}

.icon-placeholder.twenty-eight-x-28 {
  height: 28px;
  width: 28px;
}

.icon-placeholder.thirty-two-x-32 {
  height: 32px;
  width: 32px;
}
