.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.user-account-nav {
  margin-left: auto !important;
}

.main-area {
  position: relative;
  z-index: 1;
  padding: 0 20px;
  background-size: cover;
  color: #fff;
}

.main-area:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0.4;
}

.main-area .desc {
  margin: 20px auto;
  max-width: 500px;
}

.center-text {
  text-align: center;
}

.display-table {
  display: table;
  height: 100%;
  width: 100%;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.nav-side-footer {
  max-height: 60px;
  padding: 5px !important;
}

.nav-side-footer__image {
  height: 50px;
  width: 246px;
}

.side-bar-brand {
  background: transparent !important;
}

.sidebar-nav {
  border-top: 1px solid #ffffff1a !important;
}

.user-name-avatar {
  border-radius: 50%;
  background-color: #ffffff0d;
  font-weight: 600;
}

.user-name-full {
  text-align: center;
  font-size: 0.975rem;
}

// span.completed::after
.completed.new-assessment-request-step {
  span.completed::after {
    content: '\2713';
    display: inline-block;
    color: #fff;
    font-weight: bolder;
  }
}

button.new-assessment-request-step:disabled {
  background-color: rgb(255 255 255 / 80%);
}

.assessment-request-modal {
  span#RFS-Label {
    font-weight: 700;
  }
}

@media (min-width: 992px) {
  .assessment-request-modal .modal-lg,
  .assessment-request-modal .modal-xl {
    --cui-modal-width: 920px !important;
  }
}

// .active.new-assessment-request-step {
//   span.active::after {
//     font-family: "Font Awesome 5 Free";
//     content: "\f110";
//     display: inline-block;
//     padding-right: 3px;
//     vertical-align: middle;
//     font-weight: 900;
//   }
// }

a.nav-link {
  cursor: default;
}

.doc-viewer {
  min-height: calc(80vh);
}

.doc-viewer #header-bar {
  display: block;
  position: absolute;
  top: 70px;
}

.required.form-label:before {
  content: '*';
  color: red;
}
