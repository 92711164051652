.dashboard-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  box-sizing: border-box;
  gap: 16px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--paragraph-r-p3-size) !important;
  color: var(--grey-900) !important;
  font-family: var(--paragraph-m-p2-font-family);
  border-radius: 8px !important;
  background-color: var(--collection-BG-100) !important;
}

@media screen and (max-width: 725px) {
  .dashboard-card {
    font-family: var(--paragraph-m-p2-font-family);
    flex-wrap: wrap;
  }
}

.table {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  background-color: transparent;
}

.tablehead {
  :global {
    .MuiTableCell-root {
      color: var(--neutral-color-grey-600);
      border-bottom-color: #ffffff14;
      background-color: transparent;
    }
  }
}

.tablebody {
  :global {
    .MuiTableCell-root {
      color: var(--neutral-color-grey-750);
      border-bottom-color: #ffffff0a;
      background-color: #0000001a;
    }
  }
}

.card {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 8px !important;
}

.success-col {
  color: #30ac79 !important;
}

.warning-col {
  color: #ef4d4f !important;
}

.info-col {
  color: rgba(254, 170, 50, 1) !important;
}

.apexChart {
  :global {
    .apexcharts-legend-text {
      margin-left: -10px !important;
    }
  }
}
