.section {
  align-items: center;
  background-color: var(--collection-BG-500) !important;
  border-radius: 12px !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px 16px 0px;
  position: relative;
  width: 100%;
  border: 1px solid;
  border-color: transparent;
  overflow-x: auto;
}

.headerborder {
  align-self: stretch;
  background-color: #ffffff14;
  height: 1px;
  position: relative;
  width: 100%;
}


.legends {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.legends .div {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}


.legends .text-wrapper {
  color: var(--neutral-color-grey-600);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.legends .rectangle {
  border-radius: 2px;
  height: 12px;
  position: relative;
  width: 12px;
}

.controltestingchart {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 24px 16px;
}

.metrics {
  align-items: center;
  background-color: #0000001a;
  border-radius: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  padding: 12px;
  position: relative;
}

.sub-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  padding: 16px 12px;
  position: relative;
  width: 100%;
}

.divider {
  align-self: stretch;
  background-color: #ffffff14;
  border-radius: 2px;
  position: relative;
  width: 1px;
}

.sub-section-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 16px 0px;
  position: relative;
  width: 100%;
}

.half-doughnut-chart-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
}

.half-doughnut-chart {
  height: 200px;
  position: relative;
  width: 200px;
}