.card-screen {
  align-items: flex-start;
  background-color: var(--collection-BG-500);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 16px;
  position: relative;
  overflow-x: auto;
}

.card-screen .rectangle {
  align-self: stretch;
  background-color: #ffffff14;
  height: 1px;
  position: relative;
  width: 100%;
}

.card {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 0px 16px;
  position: relative;
  width: 100%;
}

.metrics {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.divider {
  background-color: #ffffff1f;
  border-radius: 2px;
  height: 64px;
  position: relative;
  width: 1px;
}

.divider-2 {
  background-color: #ffffff1f;
  border-radius: 2px;
  height: 16px;
  position: relative;
  width: 1px;
}

.chart-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chart-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chart {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  position: relative;
  width: 50%;
}

.chart .half-doughnut-chart {
  height: 200px;
  position: relative;
  width: 200px;
}

.chart .legends {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: center;
  position: relative;
}

.chart .legends-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
}

.chart .test {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.chart .text-wrapper-6 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-m-p2-font-family);
  font-size: var(--paragraph-m-p2-font-size);
  font-style: var(--paragraph-m-p2-font-style);
  font-weight: var(--paragraph-m-p2-font-weight);
  letter-spacing: var(--paragraph-m-p2-letter-spacing);
  line-height: var(--paragraph-m-p2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chart .num {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.chart .text-wrapper-7 {
  color: var(--neutral-color-grey-750);
  font-family: var(--heading-m-h2-font-family);
  font-size: var(--heading-m-h2-font-size);
  font-style: var(--heading-m-h2-font-style);
  font-weight: var(--heading-m-h2-font-weight);
  letter-spacing: var(--heading-m-h2-letter-spacing);
  line-height: var(--heading-m-h2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chart .line {
  align-self: stretch;
  object-fit: cover;
  position: relative;
  width: 1px;
}

.section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 278px;
}

.section .title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.section .text-wrapper-8 {
  color: var(--neutral-color-grey-750);
  font-family: var(--heading-m-h5-font-family);
  font-size: var(--heading-m-h5-font-size);
  font-style: var(--heading-m-h5-font-style);
  font-weight: var(--heading-m-h5-font-weight);
  letter-spacing: var(--heading-m-h5-letter-spacing);
  line-height: var(--heading-m-h5-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.section .table {
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid;
  border-color: #ffffff0a;
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  overflow: auto;
  position: relative;
  width: 100%;
}

.section .col {
  align-items: flex-start;
  background-color: #0000001a;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.section .cell {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #ffffff0a;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 10px 12px;
  position: relative;
  width: 100%;
}

.section .text-wrapper-9 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.section .cell-2 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #ffffff0a;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  margin-left: -1px;
  margin-right: -1px;
  padding: 10px 12px;
  position: relative;
  width: 100%;
}

.section .cell-3 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #ffffff0a;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  padding: 10px 12px;
  position: relative;
  width: 100%;
}

.section-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.frames {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 24px 0px 0px;
  position: relative;
  width: 100%;
}

.frames .text-wrapper-35 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-SB-p1-font-family);
  font-size: var(--paragraph-SB-p1-font-size);
  font-style: var(--paragraph-SB-p1-font-style);
  font-weight: var(--paragraph-SB-p1-font-weight);
  letter-spacing: var(--paragraph-SB-p1-letter-spacing);
  line-height: var(--paragraph-SB-p1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frames .legends-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.frames .row-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.frames .icon-placeholder-instance {
  height: 24px !important;
  width: 24px !important;
}

.frames .icon-instance-node {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.frames .text-wrapper-36 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frames .line-2 {
  height: 16px;
  object-fit: cover;
  position: relative;
  width: 1px;
}

.frames .text-wrapper-37 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.section-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--collection-BG-300);
  border-radius: 12px;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  padding: 20px;
  position: relative;
  width: 100%;
  overflow: auto;
}

.content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  position: relative;
}

.row {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #ffffff14;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  padding: 0px 0px 12px;
  position: relative;
  width: 100%;
}

.row .question {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.row .text-icon {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.row .icons-6 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.row .text {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.row .documented-privacy {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin-bottom: 0px;
}

.row .text-wrapper-10 {
  color: var(--neutral-color-grey-600);
  font-family: var(--paragraph-SB-p1-font-family);
  font-size: var(--paragraph-SB-p1-font-size);
  font-style: var(--paragraph-SB-p1-font-style);
  font-weight: var(--paragraph-SB-p1-font-weight);
  letter-spacing: var(--paragraph-SB-p1-letter-spacing);
  line-height: var(--paragraph-SB-p1-line-height);
  position: relative;
  width: 259px;
}

.content-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  position: relative;
  width: 100%;
}
