.report-wrapper {
  align-items: flex-start;
  align-self: stretch;
  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 32px; */
  height: 100%;
  overflow-y: auto;
  padding: 0px 20px 50px;
  position: relative;
  width: 100%;
}