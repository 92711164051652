.report-wrapper {
  align-items: flex-start;
  align-self: stretch;
  height: 100%;
  overflow: auto;
  padding: 40px 20px 100px;
  position: relative;
  width: 100%;
}

.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.remediation {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.cards-secetion {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: relative;
  width: 100%;
}

.empty-remediation-cards {
  align-items: flex-start;
  /* background-color: var(--collection-BG-500); */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  /* width: 30%; */
}
