
.circle-loader:nth-child(even) {
  animation: fadeInEven 5s infinite;
 }

 .circle-loader:nth-child(odd) {
  animation: fadeInOdd 1s infinite;
 }

 @keyframes fadeInEven {
   0% { opacity: 0.05; fill: #55ba90  }
   20% { opacity: 0.2; fill: #71e63e }
   40% { opacity: 0.4; fill: #55ba90 }
   60% { opacity: 0.6; fill: #71e63e }
   80% { opacity: 0.8; fill: #55ba90 }
   100% { opacity: 1.0; fill: #71e63e }
 }


 @keyframes fadeInOdd {
   10% { opacity: 0.1; fill: #71e63e }
   30% { opacity: 0.3; fill: #55ba90 }
   50% { opacity: 0.5; fill: #55ba90 }
   70% { opacity: 0.7; fill: #71e63e }
   90% { opacity: 0.8; fill: #55ba90 }
   100% { opacity: 1.0; fill: #71e63e }
 }
