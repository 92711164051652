
.section {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 54px;
  position: absolute;
  top: 75px;
}

.value {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  /* gap: 4px; */
  justify-content: center;
  position: relative;
}

.element {
  color: var(--neutral-color-grey-950);
  font-family: "SF Pro Display-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  margin-bottom: 0px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.percentage-wrapper {
  font-family: var(--heading-SB-h3-font-family);
  font-size: var(--heading-SB-h3-font-size);
  font-style: var(--heading-SB-h3-font-style);
  font-weight: var(--heading-SB-h3-font-weight);
  letter-spacing: var(--heading-SB-h3-letter-spacing);
  line-height: var(--heading-SB-h3-line-height);
}

.symbol-wrapper {
  font-family: var(--paragraph-SB-p1-font-family);
  font-size: var(--paragraph-SB-p1-font-size);
  font-style: var(--paragraph-SB-p1-font-style);
  font-weight: var(--paragraph-SB-p1-font-weight);
  letter-spacing: var(--paragraph-SB-p1-letter-spacing);
  line-height: var(--paragraph-SB-p1-line-height);
}

.caption-wrapper {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-m-p3-font-family);
  font-size: var(--paragraph-m-p3-font-size);
  font-style: var(--paragraph-m-p3-font-style);
  font-weight: var(--paragraph-m-p3-font-weight);
  letter-spacing: var(--paragraph-m-p3-letter-spacing);
  line-height: var(--paragraph-m-p3-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.ranges {
  background-size: 100% 100%;
  height: 172px;
  left: 14px;
  position: absolute;
  top: 14px;
  width: 172px;
}

/* .overlap-group {
  background-size: 100% 100%;
  height: 172px;
  position: relative;
} */

.alert {
  height: 172px;
  left: 0;
  position: absolute;
  top: 0;
  width: 172px;
}

.text-wrapper-100pixel {
  color: var(--neutral-color-grey-450);
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 8px;
  font-weight: 400;
  height: 16px;
  left: 68px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: right;
  top: 7px;
  white-space: nowrap;
}

.text-wrapper-40pixel {
  color: var(--neutral-color-grey-450);
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 8px;
  font-weight: 400;
  height: 16px;
  left: 125px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 130px;
  white-space: nowrap;
}

.text-wrapper-70pixel {
  color: var(--neutral-color-grey-450);
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 8px;
  font-weight: 400;
  height: 16px;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 99px;
  white-space: nowrap;
}

.overlap {
  height: 200px;
  left: 127px;
  position: relative;
  top: 1px;
  width: 200px;
}

.current-value {
  background-image: url(https://c.animaapp.com/xm71rFWb/img/track.svg);
  background-size: 100% 100%;
  height: 200px;
  left: 0;
  position: absolute;
  top: 0;
  width: 200px;
}

.overlap-group {
  height: 200px;
  left: -23px;
  position: absolute;
  top: 0;
  width: 260px;
}

.track {
  height: 200px;
  left: 23px;
  position: absolute;
  top: 0;
  width: 200px;
}

.vector {
  height: 29px;
  left: 214px;
  position: absolute;
  top: 112px;
  width: 46px;
}

.vector-2 {
  height: 29px;
  left: 0;
  position: absolute;
  top: 32px;
  width: 46px;
}

.text-wrapper {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  height: 18px;
  left: 249px;
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  position: absolute;
  top: 131px;
  white-space: nowrap;
}

.div {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  height: 18px;
  left: -120px;
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.text-wrapper-2 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  height: 18px;
  left: -49px;
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  position: absolute;
  top: 42px;
  white-space: nowrap;
}

.text-wrapper-3 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  height: 18px;
  left: 249px;
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  position: absolute;
  top: 151px;
  white-space: nowrap;
}
