.title-caption {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  margin-top: 32px;
}

.title-caption .level {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.title-caption .text-wrapper {
  color: var(--neutral-color-grey-950);
  font-family: var(--heading-m-h4-font-family);
  font-size: var(--heading-m-h4-font-size);
  font-style: var(--heading-m-h4-font-style);
  font-weight: var(--heading-m-h4-font-weight);
  letter-spacing: var(--heading-m-h4-letter-spacing);
  line-height: var(--heading-m-h4-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.title-caption .icons-1 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.title-caption .div {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--neutral-color-grey-600);
  display: -webkit-box;
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}
