.modal {
  /* align-items: center;
  background-color: var(--collection-BG-500);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%; */

  align-items: center;
  background-color: var(--collection-BG-500);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: auto;
}

.title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 24px 24px 0px;
  position: relative;
  width: 100%;
}

.title .title-icon {
  align-items: center;
  display: flex;
  gap: 4px;
  position: relative;
}

.title .text-wrapper-12 {
  color: var(--neutral-color-grey-950);
  font-family: var(--heading-m-h5-font-family);
  font-size: var(--heading-m-h5-font-size);
  font-style: var(--heading-m-h5-font-style);
  font-weight: var(--heading-m-h5-font-weight);
  letter-spacing: var(--heading-m-h5-letter-spacing);
  line-height: var(--heading-m-h5-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.title .icons-6 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  padding: 24px;
  position: relative;
  width: 100%;
}

.section .rectangle-6 {
  align-self: stretch;
  background-color: #ffffff14;
  position: relative;
  width: 1px;
}

.control-eff-wrapper {
  /* align-items: flex-start;
  align-self: stretch; */
  border-radius: 4px;
  box-shadow: var(--shadow-lg);
  background: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  padding: 16px;
  position: relative;
}

.control-eff-wrapper .content-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.control-eff-wrapper .span-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  padding: 0px 3px 0px 0px;
  position: relative;
}

.control-eff-wrapper .text-wrapper-4 {
  color: var(--confirmation-color-CC-l-150);
  font-family: var(--heading-SB-h4-font-family);
  font-size: var(--heading-SB-h4-font-size);
  font-style: var(--heading-SB-h4-font-style);
  font-weight: var(--heading-SB-h4-font-weight);
  letter-spacing: var(--heading-SB-h4-letter-spacing);
  line-height: var(--heading-SB-h4-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.control-eff-wrapper .text-wrapper-5 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-m-p3-font-family);
  font-size: var(--paragraph-m-p3-font-size);
  font-style: var(--paragraph-m-p3-font-style);
  font-weight: var(--paragraph-m-p3-font-weight);
  letter-spacing: var(--paragraph-m-p3-letter-spacing);
  line-height: var(--paragraph-m-p3-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.control-eff-wrapper .risk-level {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.control-eff-wrapper .high-2 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #ffffff14;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 0px 8px;
  position: relative;
  width: 100%;
}

.control-eff-wrapper .value-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.control-eff-wrapper .text-wrapper-6 {
  color: var(--neutral-color-grey-600);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.control-eff-wrapper .text-wrapper-7 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-m-p2-font-family);
  font-size: var(--paragraph-m-p2-font-size);
  font-style: var(--paragraph-m-p2-font-style);
  font-weight: var(--paragraph-m-p2-font-weight);
  letter-spacing: var(--paragraph-m-p2-letter-spacing);
  line-height: var(--paragraph-m-p2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.control-eff-wrapper .high-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.div-wrapper {
  /* align-items: flex-start; */
  /* align-self: stretch; */
  border-radius: 4px;
  box-shadow: var(--shadow-lg);
  background: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  padding: 16px;
  position: relative;
  /* width: 40%; */
}

.div-wrapper .risk-level-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.div-wrapper .high-4 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #ffffff14;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 0px 8px;
  position: relative;
  width: 100%;
}

.div-wrapper .value-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.div-wrapper .text-wrapper-10 {
  color: var(--neutral-color-grey-600);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-wrapper .text-wrapper-11 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-m-p2-font-family);
  font-size: var(--paragraph-m-p2-font-size);
  font-style: var(--paragraph-m-p2-font-style);
  font-weight: var(--paragraph-m-p2-font-weight);
  letter-spacing: var(--paragraph-m-p2-letter-spacing);
  line-height: var(--paragraph-m-p2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  /* margin-right: 10px; */
}

.div-wrapper .high-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: fit-content;
  gap: 20px;
}

.inherent-risk {
  border-radius: 4px;
  box-shadow: var(--shadow-lg);
  background: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  padding: 16px;
  position: relative;
  /* width: 40%; */
}

.inherent-risk .section-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.inherent-risk .section-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.inherent-risk .high {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.inherent-risk .value {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.inherent-risk .value-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.inherent-risk .text-wrapper-2 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.inherent-risk .text-wrapper-3 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-m-p2-font-family);
  font-size: var(--paragraph-m-p2-font-size);
  font-style: var(--paragraph-m-p2-font-style);
  font-weight: var(--paragraph-m-p2-font-weight);
  letter-spacing: var(--paragraph-m-p2-letter-spacing);
  line-height: var(--paragraph-m-p2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.inherent-risk .levels {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
  width: 100%;
}

.inherent-risk .rectangle {
  background-color: var(--neutral-color-grey-150);
  border-radius: 12px 0px 0px 12px;
  flex: 1;
  flex-grow: 1;
  height: 10px;
  position: relative;
}

.inherent-risk .rectangle-flat {
  background-color: var(--neutral-color-grey-150);
  flex: 1;
  flex-grow: 1;
  height: 10px;
  position: relative;
}

.inherent-risk .rectangle-last {
  background-color: var(--neutral-color-grey-150);
  border-radius: 0px 12px 12px 0px;
  flex: 1;
  flex-grow: 1;
  height: 10px;
  position: relative;
}

.inherent-risk .rectangle-no {
  /* background-color: var(--neutral-color-grey-150); */
  background-color: var(--error-color-EC-l-150);
  border-radius: 0px 12px 12px 0px;
  flex: 1;
  flex-grow: 1;
  height: 10px;
  position: relative;
}

.inherent-risk .rectangle-yes {
  background-color: var(--confirmation-color-CC-l-150);
  border-radius: 12px 0px 0px 12px;
  flex: 1;
  flex-grow: 1;
  height: 10px;
  position: relative;
}

.inherent-risk .rectangle-empty {
  background-color: var(--neutral-color-grey-150);
  border-radius: 0px 12px 12px 0px;
  flex: 1;
  flex-grow: 1;
  height: 10px;
  position: relative;
}
