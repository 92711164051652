.section {
  align-items: center;
  background-color: var(--collection-BG-500) !important;
  border-radius: 12px !important;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  padding: 24px 16px 0px;
  position: relative;
  width: 100%;
  border: 1px solid;
  border-color: transparent;
  margin-top: 32px;
  overflow-x: auto;
}

/*
.section .title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  font-size:x-large;
}

.section .text-wrapper {
  color: var(--neutral-color-grey-950);
  font-family: var(--heading-m-h2-font-family);
  font-size: var(--heading-m-h2-font-size);
  font-style: var(--heading-m-h2-font-style);
  font-weight: var(--heading-m-h2-font-weight);
  letter-spacing: var(--heading-m-h2-letter-spacing);
  line-height: var(--heading-m-h2-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.section .div {
  color: var(--neutral-color-grey-950);
  font-family: var(--paragraph-r-p1-font-family);
  font-size: var(--paragraph-r-p1-font-size);
  font-style: var(--paragraph-r-p1-font-style);
  font-weight: var(--paragraph-r-p1-font-weight);
  letter-spacing: var(--paragraph-r-p1-letter-spacing);
  line-height: var(--paragraph-r-p1-line-height);
  position: relative;
  text-align: center;
  margin-bottom: 0px;
} */

.section .title {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 100%;
}

.section .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.section .text-wrapper {
  color: var(--neutral-color-grey-950);
  font-family: var(--heading-m-h4-font-family);
  font-size: var(--heading-m-h4-font-size);
  font-style: var(--heading-m-h4-font-style);
  font-weight: var(--heading-m-h4-font-weight);
  letter-spacing: var(--heading-m-h4-letter-spacing);
  line-height: var(--heading-m-h4-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.section .button {
  align-items: center;
  border-radius: 6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  height: 34px;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.section .view-details-instance {
  flex: 0 0 auto !important;
  color: var(--info-color-IC-l-300);
  font-family: var(--paragraph-m-p2-font-family);
  font-size: var(--paragraph-m-p2-font-size);
  font-style: var(--paragraph-m-p2-font-style);
  font-weight: var(--paragraph-m-p2-font-weight);
  letter-spacing: var(--paragraph-m-p2-letter-spacing);
  line-height: var(--paragraph-m-p2-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.section .icons-7 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.section .div-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  max-width: 80%;
  position: relative;
  width: 80%;
}

.section .p {
  align-self: stretch;
  color: var(--neutral-color-grey-600);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: 16px;
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  position: relative;
}

.section .card {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.section .metrics {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
  justify-content: center;
}

.section .divider {
  background-color: #ffffff1f;
  border-radius: 2px;
  height: 64px;
  position: relative;
  width: 1px;
}
