.card {
  align-items: flex-start;
  background-color: var(--collection-BG-500);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 24px 16px;
  position: relative;
  overflow-x: auto;
}

.card .chart {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 48px;
  justify-content: center;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
}

.chart .frame {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
}

.frame .half-doughnut-chart {
  height: 200px;
  position: relative;
  width: 200px;
}

.frame .legends {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.frame .div-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.frame .rectangle {
  background-color: var(--info-color-IC-l-150);
  border-radius: 2px;
  height: 12px;
  position: relative;
  width: 12px;
}

.frame .text-wrapper-32 {
  color: var(--neutral-color-grey-600);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .rectangle-2 {
  background-color: var(--warning-color-2-WC-l-150);
  border-radius: 2px;
  height: 12px;
  position: relative;
  width: 12px;
}

.metrics {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.metrics .sub-section {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  padding: 12px 0px;
  position: relative;
}

.metrics .divider {
  align-self: stretch;
  background-color: #ffffff14;
  border-radius: 2px;
  position: relative;
  width: 1px;
}

.list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 292px;
}

.metric-card {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.metric-card .text-wrapper {
  align-self: stretch;
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -1px;
  position: relative;
}

.metric-card .progress-bar {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.metric-card .overlap-group-wrapper {
  background-color: var(--neutral-color-grey-150);
  border-radius: 8px;
  flex: 1;
  flex-grow: 1;
  height: 8px;
  position: relative;
}

.metric-card .overlap-group {
  height: 18px;
  position: relative;
  top: -5px;
  width: 174px;
}

.metric-card .vendor-score {
  background-color: var(--info-color-IC-l-150);
  border-radius: 4px 0px 0px 4px;
  height: 8px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 174px;
}

.metric-card .industry-score {
  background-color: var(--warning-color-2-WC-l-150);
  border: 1px solid;
  border-color: var(--collection-BG-500);
  border-radius: 12px;
  height: 18px;
  left: 109px;
  position: absolute;
  top: 0;
  width: 6px;
}

.metric-card .percentage {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-SB-p3-font-family);
  font-size: var(--paragraph-SB-p3-font-size);
  font-style: var(--paragraph-SB-p3-font-style);
  font-weight: var(--paragraph-SB-p3-font-weight);
  letter-spacing: var(--paragraph-SB-p3-letter-spacing);
  line-height: var(--paragraph-SB-p3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.metric-card .text-wrapper-2 {
  color: var(--neutral-color-grey-750);
  font-family: var(--caption-m-SB-c-m-font-family);
  font-size: var(--caption-m-SB-c-m-font-size);
  font-style: var(--caption-m-SB-c-m-font-style);
  font-weight: var(--caption-m-SB-c-m-font-weight);
  height: 16px;
  left: 124px;
  letter-spacing: var(--caption-m-SB-c-m-letter-spacing);
  line-height: var(--caption-m-SB-c-m-line-height);
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

.list-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 292px;
}
