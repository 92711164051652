.remediation-cards {
  align-items: flex-end;
  background-color: var(--collection-BG-500);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.remediation-cards .header {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--collection-BG-500);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 8px;
  position: relative;
  width: 100%;
}

.remediation-cards .level {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}

.remediation-cards .content {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  position: relative;
}

.remediation-cards .title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.remediation-cards .icon-instance-node {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.remediation-cards .text-wrapper {
  color: var(--neutral-color-grey-750);
  font-family: var(--heading-SB-h5-font-family);
  font-size: var(--heading-SB-h5-font-size);
  font-style: var(--heading-SB-h5-font-style);
  font-weight: var(--heading-SB-h5-font-weight);
  letter-spacing: var(--heading-SB-h5-letter-spacing);
  line-height: var(--heading-SB-h5-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.remediation-cards .section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  /* padding: 0px 0px 0px 40px; */
  position: relative;
  width: 100%;
}

.remediation-cards .status-instance {
  flex: 0 0 auto !important;
}

.remediation-cards .single-content-text {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--collection-BG-500);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
  position: relative;
  width: 100%;
}

.remediation-cards .body-text {
  align-self: stretch;
  color: var(--neutral-color-grey-600);
  font-family: var(--caption-m-SB-c-SB-font-family);
  font-size: var(--caption-m-SB-c-SB-font-size);
  font-style: var(--caption-m-SB-c-SB-font-style);
  font-weight: var(--caption-m-SB-c-SB-font-weight);
  letter-spacing: var(--caption-m-SB-c-SB-letter-spacing);
  line-height: var(--caption-m-SB-c-SB-line-height);
  margin-top: -1px;
  position: relative;
}

.remediation-cards .div {
  align-self: stretch;
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  position: relative;
  margin-top: 0px;
}

.remediation-cards .single-content {
  align-items: center;
  align-self: stretch;
  background-color: var(--collection-BG-500);
  border-color: #ffffff14;
  border-top-style: solid;
  border-top-width: 0.5px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 12px 16px;
  position: relative;
  width: 100%;
  height: max-content;
}

.remediation-cards .footer {
  align-items: center;
  align-self: stretch;
  background-color: var(--collection-BG-500);
  border-color: #ffffff14;
  border-top-style: solid;
  border-top-width: 0.5px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 12px 16px;
  position: unset;
  width: 100%;
  height: 55px;
  margin-top: auto;
}

.remediation-cards .badge-status {
  align-items: center;
  background-color: #0000001f;
  border-radius: 99px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  justify-content: center;
  padding: 2px 12px 2px 2px;
  position: relative;
}

.remediation-cards .text-2 {
  color: var(--neutral-color-grey-450);
  font-family: var(--paragraph-SB-p3-font-family);
  font-size: var(--paragraph-SB-p3-font-size);
  font-style: var(--paragraph-SB-p3-font-style);
  font-weight: var(--paragraph-SB-p3-font-weight);
  letter-spacing: var(--paragraph-SB-p3-letter-spacing);
  line-height: var(--paragraph-SB-p3-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.remediation-cards .badge-statut-2 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 6px 8px;
  position: relative;
}

.remediation-cards .text-3 {
  color: var(--info-color-IC-l-300);
  font-family: var(--paragraph-SB-p2-font-family);
  font-size: var(--paragraph-SB-p2-font-size);
  font-style: var(--paragraph-SB-p2-font-style);
  font-weight: var(--paragraph-SB-p2-font-weight);
  letter-spacing: var(--paragraph-SB-p2-letter-spacing);
  line-height: var(--paragraph-SB-p2-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.status {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
}

.statustext {
  font-family: var(--caption-m-SB-c-m-font-family);
  font-size: var(--caption-m-SB-c-m-font-size);
  font-style: var(--caption-m-SB-c-m-font-style);
  font-weight: var(--caption-m-SB-c-m-font-weight);
  letter-spacing: var(--caption-m-SB-c-m-letter-spacing);
  line-height: var(--caption-m-SB-c-m-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.accepted {
  background-color: var(--confirmation-color-CC-d-150);
  border-radius: 4px;
  padding: 2px 4px;
}

.initiated {
  color: var(--warning-color-2-WC-l-150);
  padding: 2px 0px;
}

.notinitiated {
  color: var(--info-color-IC-l-300);
  padding: 2px 0px;
}

.other {
  color: var(--info-color-IC-l-300);
  padding: 2px 0px;
}

.accepted .statustext {
  color: var(--neutral-color-grey-950);
}

.closed {
  background-color: var(--error-color-EC-l-150);
  border-radius: 4px;
  padding: 2px 4px;
}

.closed .statustext {
  color: var(--neutral-color-grey-950);
}

.highrisk {
  color: var(--error-color-EC-l-150) !important;
}

.lowrisk {
  color: var(--confirmation-color-CC-l-150) !important;
}

.mediumrisk {
  color: var(--warning-color-2-WC-l-150) !important;
}
