.card {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 8px !important;
  height: 100% !important;
  background-color: inherit !important;
  padding: 16px;
}

.remediation-container {
  width: 100%;
  height: 100%;
}

.remediation-card-container {
  align-items: flex-start;
  background-color: var(--collection-bg-600);
  border-color: var(--collection-BG-200);
  border-right-style: solid;
  border-right-width: 2px;
  display: flex;
  flex-direction: column !important;
  flex-shrink: 0;
  height: 100%;
  color: var(--neutral-color-grey-750);
  padding: 0px !important;
}

.remediation-card {
  align-items: center;
  align-self: stretch;
  background-color: var(--collection-BG-100);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: var(--collection-BG-200);
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  gap: 12px;
  overflow-x: auto;
  padding: 10px 12px 6px 20px;
  position: relative;
  width: 100%;
}

.remediation-card-active {
  background-color: #3a3c3e;
}

.remediation-card:hover {
  background-color: #3a3c3e;
}

.remediation-card-header {
  display: flex;
  width: 100%;
}

.remediation-card-body {
  display: flex;
  width: 100%;
}

.remediation-card-header-business-org,
.remediation-card-body-domain {
  flex-grow: 1;
}

.remediation-card-body-domain {
  font-size: 14px;
  font-weight: 600;
}

.remediation-detail-container {
  background-color: var(--collection-BG-200);
  height: 100%;
  width: 100%;
  padding: 0px !important;
}

.remediation-sub-heading {
  color: var(--neutral-color-grey-950);
  font-family: var(--heading-m-h4-font-family);
  font-size: 18px;
  font-style: var(--heading-m-h4-font-style);
  font-weight: var(--heading-m-h4-font-weight);
  letter-spacing: var(--heading-m-h4-letter-spacing);
  line-height: var(--heading-m-h4-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.remediation-details {
  padding: 24px 12px;
}

// .heading {
//     font-size: 18px;
//     font-weight: 500;
//     line-height: 22px;
//     text-align: left;
//     color: #F3F3F4;

// }

// .description {
//     font-size: 16px;
//     font-weight: 400;
//     line-height: 24px;
//     text-align: left;
//     color:  #E8E8E8;
// }

.modal {
  //align-items: flex-start;
  background-color: var(--collection-BG-500);
  border-radius: 12px;
  // display: flex;
  // flex-direction: column;
  // position: relative;
  width: 480px;
}

.title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 24px 24px 0px;
  position: relative;
  width: 100%;
}

.title-icon {
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  width: 246px;
}

.icon-instance-node {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.text-wrapper {
  color: var(--neutral-color-grey-950);
  font-family: var(--heading-SB-h5-font-family);
  font-size: var(--heading-SB-h5-font-size);
  font-style: var(--heading-SB-h5-font-style);
  font-weight: var(--heading-SB-h5-font-weight);
  letter-spacing: var(--heading-SB-h5-letter-spacing);
  line-height: var(--heading-SB-h5-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.description {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 24px 24px 10px 24px;
  position: relative;
  width: 100%;
}

.description-text {
  align-self: stretch;
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p1-font-family);
  font-size: var(--paragraph-r-p1-font-size);
  font-style: var(--paragraph-r-p1-font-style);
  font-weight: var(--paragraph-r-p1-font-weight);
  letter-spacing: var(--paragraph-r-p1-letter-spacing);
  line-height: var(--paragraph-r-p1-line-height);
  margin-top: -1px;
  margin-bottom: 0px;
  position: relative;
}

.div {
  align-items: flex-start;
  align-self: stretch;
  border-color: #ffffff14;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: flex-end;
  padding: 12px 24px;
  position: relative;
  width: 100%;
}

.button-instance {
  flex: 0 0 auto !important;
}

.design-component-instance-node {
  color: var(--error-color-EC-l-150) !important;
}

.reason {
  align-items: center;
  align-self: stretch;
  background-color: var(--collection-BG-500);
  border: 1px solid;
  border-color: rgb(77 79 83);
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  gap: 2px;
  margin-bottom: -2px;
  margin-left: -2px;
  margin-right: -2px;
  padding: 8px 12px !important;
  position: relative;
  width: 100%;
}

.loader {
  position: relative;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
