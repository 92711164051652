.card {
  align-items: flex-start;
  background-color: var(--collection-BG-500);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 24px 16px;
  position: relative;
  /* overflow-x: auto; */
}

.legend {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  padding: 0px 12px 0px 0px;
  position: relative;
  width: 100%;
}

.legend .item {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.legend .span {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.legend .div-2 {
  background-color: var(--info-color-IC-l-150);
  border-radius: 12px;
  height: 12px;
  position: relative;
  width: 12px;
}

.legend .text-wrapper-18 {
  color: var(--neutral-color-grey-600);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.legend .div-3 {
  background-color: var(--warning-color-2-WC-l-150);
  border-radius: 12px;
  height: 12px;
  position: relative;
  width: 12px;
}

.legend .line-6 {
  height: 1px;
  position: relative;
  width: 24px;
}

.card .rectangle-7 {
  align-self: stretch;
  background-color: #ffffff14;
  height: 1px;
  position: relative;
  width: 100%;
}

.security-incidents {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.security-incidents .legends {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.security-incidents .failing {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.security-incidents .text-wrapper-19 {
  color: var(--neutral-color-grey-950);
  font-family: var(--heading-m-h5-font-family);
  font-size: var(--heading-m-h5-font-size);
  font-style: var(--heading-m-h5-font-style);
  font-weight: var(--heading-m-h5-font-weight);
  letter-spacing: var(--heading-m-h5-letter-spacing);
  line-height: var(--heading-m-h5-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.security-incidents .badge {
  align-items: center;
  background-color: var(--error-color-EC-d-150);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: 20px;
  justify-content: center;
  position: relative;
  width: 20px;
}

.security-incidents .text-wrapper-20 {
  color: var(--neutral-color-grey-950);
  font-family: var(--paragraph-r-p3-font-family);
  font-size: var(--paragraph-r-p3-font-size);
  font-style: var(--paragraph-r-p3-font-style);
  font-weight: var(--paragraph-r-p3-font-weight);
  letter-spacing: var(--paragraph-r-p3-letter-spacing);
  line-height: var(--paragraph-r-p3-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.security-incidents .legends-2 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.security-incidents .failing-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
}

/* .security-incidents .iconBadge  {
  background-color: var(--neutral-color-grey-150);
  border-radius: 100px;
  height: 54px;
  overflow: hidden;
  position: relative;
  width: 54px;
} */

.icon {
  background-color: var(--error-color-EC-l-300);
  border-radius: 99px;
  height: 45px;
  overflow: auto;
  position: relative;
  width: 45px;
}

.icon .frame {
  height: 24px;
  left: 9px;
  position: relative;
  top: 10px;
  width: 24px;
}

.security-incidents .data {
  align-items: flex-start;
  display: inline-flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.security-incidents .text-wrapper-21 {
  color: var(--neutral-color-grey-950);
  font-family: var(--paragraph-m-p1-font-family);
  font-size: var(--paragraph-m-p1-font-size);
  font-style: var(--paragraph-m-p1-font-style);
  font-weight: var(--paragraph-m-p1-font-weight);
  letter-spacing: var(--paragraph-m-p1-letter-spacing);
  line-height: var(--paragraph-m-p1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.security-incidents .p {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  /* height: 36px; */
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  position: relative;
  width: fit-content;
  margin-bottom: 0px;
}

.div-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.div-wrapper .title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.div-wrapper .text-wrapper-14 {
  color: var(--neutral-color-grey-750);
  font-family: var(--heading-m-h5-font-family);
  font-size: var(--heading-m-h5-font-size);
  font-style: var(--heading-m-h5-font-style);
  font-weight: var(--heading-m-h5-font-weight);
  letter-spacing: var(--heading-m-h5-letter-spacing);
  line-height: var(--heading-m-h5-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-wrapper .table {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff0a;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  overflow: auto;
  position: relative;
}

.div-wrapper .col {
  align-items: flex-start;
  background-color: #0000001a;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 120px;
}

.div-wrapper .text-wrapper-15 {
  color: var(--neutral-color-grey-750);
  font-family: var(--paragraph-r-p2-font-family);
  font-size: var(--paragraph-r-p2-font-size);
  font-style: var(--paragraph-r-p2-font-style);
  font-weight: var(--paragraph-r-p2-font-weight);
  letter-spacing: var(--paragraph-r-p2-letter-spacing);
  line-height: var(--paragraph-r-p2-line-height);
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-wrapper .cell-2 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #ffffff0a;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  margin-left: -1px;
  margin-right: -1px;
  padding: 10px 10px;
  position: relative;
  width: 100%;
  justify-content: center;
}

.div-wrapper .text-wrapper-16 {
  color: var(--neutral-color-grey-750);
  flex: 1;
  font-family: var(--paragraph-SB-p2-font-family);
  font-size: var(--paragraph-SB-p2-font-size);
  font-style: var(--paragraph-SB-p2-font-style);
  font-weight: var(--paragraph-SB-p2-font-weight);
  letter-spacing: var(--paragraph-SB-p2-letter-spacing);
  line-height: var(--paragraph-SB-p2-line-height);
  margin-top: -0.5px;
  position: relative;
  text-align: center;
}

.div-wrapper .text-wrapper-17 {
  color: var(--neutral-color-grey-450);
  font-family: var(--caption-m-SB-c-m-font-family);
  font-size: var(--caption-m-SB-c-m-font-size);
  font-style: var(--caption-m-SB-c-m-font-style);
  font-weight: var(--caption-m-SB-c-m-font-weight);
  letter-spacing: var(--caption-m-SB-c-m-letter-spacing);
  line-height: var(--caption-m-SB-c-m-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.graph {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 40px;
  position: relative;
}

.chart {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  padding: 0px 32px;
  position: relative;
  width: 100%;
}
